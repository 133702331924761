@import '../../default';

.page-user{

    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    box-sizing: border-box;

   > header{
        width: 100%;
        height: 69px;
    }

    section{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: calc(100% - 89px);
        box-sizing: border-box;

        nav{
            width: 100%;
            min-width: 60px;
            max-width: 60px;
            background-color: $color-tokin-gray-2;
            transition: all ease 600ms;
            overflow: hidden;
            display: flex;
            align-items: stretch;
            box-sizing: border-box;
            padding: 10px 15px;
            transform-origin: top left;

            @media screen and (max-width: 400px) {
                max-width: 0;
                min-width: 0;
                padding: 0;
            }
            
            &.active{
                width: 320px;
                max-width: 320px;
                min-width: 320px;
                margin-left: 0px;
            }

            

        }

        main{
            box-sizing: border-box;
            padding: 20px;
            width: 100%;
            overflow: auto;
            overflow-x: hidden;
        }
    }

    footer{
        width: 100%;
        height: 20px;
        background-color: $color-tokin-gray-2;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.7em;
    }

}