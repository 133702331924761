@import '../../../default';

.page-user-dashboard{

    box-sizing: border-box;
    margin-bottom: 20px;
    height: auto;

   > header{
        margin: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 10px;
        min-width: 300px;

        @media screen  and (max-width: 767px){
            justify-content: center;
            padding: 0;

            > div{
                margin: 10px 0 !important;
            }
        }

        > div{
            margin: 10px;
        }
    }

    .toolbar{
        display: grid;
        grid-template-columns: 150px 50px 150px 80px;
        background-color: $color-tokin-gray-2;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 5px;
        align-items: center;
        justify-content: flex-end;
        row-gap: 10px;
        column-gap: 10px;
        min-width: 300px;

        @media screen and (max-width: 500px) {
            grid-template-columns: 1fr;

            > .p-button{
                width: 100% !important;
                max-width: 100% !important;
            }
        }

        .p-calendar{
            width: 100%;
        }

        > .p-button{
            max-width: 80px;
        }

        .p-multiselect{
            min-width: 250px;
        }

        .p-multiselect-label-container{
            min-width: 150px;
        }
    }

    .toolbar-chart{
        display: grid;
        grid-template-columns: 250px 150px;
        background-color: $color-tokin-gray-2;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 5px;
        align-items: center;
        justify-content: flex-end;
        row-gap: 10px;
        column-gap: 10px;
        min-width: 300px;

        @media screen and (max-width: 500px) {
            grid-template-columns: 1fr;

            > .p-button{
                width: 100% !important;
                max-width: 100% !important;
            }
        }

        .p-calendar{
            width: 100%;
        }

        > .p-button{
            max-width: 80px;
        }

        .p-multiselect{
            min-width: 250px;
        }

        .p-multiselect-label-container{
            min-width: 150px;
        }
    }

    .chart-area{
       min-width: 300px;
    }

    .load{
        position: relative;

        &::before{
            content: 'Carregando';
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            color: #FFF;
        }
    }

}