@import '../../../default';

.menu-user{

    width: 100%;

    ul{
        margin: 0;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;

        li{
            list-style: none;
            display: flex;
            min-height: 32px;
            align-items: center;
            transition: all ease 600ms 0.2s;
            width: 100%;

            &.click{
                cursor: pointer;
            }

            &.title{
                font-weight: 700;
                font-size: 1em;
                margin: 5px 0;
            }
            
            .icon{
                width: 32px;
                min-width: 32px;
                height: 32px;
                min-height: 32px;
                display: flex;
                align-items: center;
                border-radius: 50%;
                transition: all ease 600ms;

                i{
                    margin: 0 auto;
                }

                &:hover{
                    background-color: $color-tokin-green-1;
                    transition: all ease 600ms;
                }
            }

            .text{
                width: 100%;
                display: flex;
                font-size: 0.9em;
                transition: all ease 600ms;
                transform-origin: center left;
                transform: scaleX(0);
            }
        }

        &.active{


            li{
                &:hover{
                    margin-left: 15px;
                    transition: all ease 600ms;
                }
            }

            .icon{
                
                &:hover{
                    background-color: transparent;
                    transition: all ease 600ms;
                }
            }

            .text{
                display: flex;
                transition: all ease 600ms;
                transform: scaleX(1);
            }
        }

    }
}