@import 'default';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');


@import 'primeflex/primeflex.css';
@import 'primereact/resources/themes/saga-blue/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';

@import 'primeflex/src/_variables.scss';
@import 'primeflex/src/_grid.scss';
@import 'primeflex/src/_formlayout.scss';
@import 'primeflex/src/_display.scss';
@import 'primeflex/src/_text.scss';
@import 'primeflex/src/flexbox/_flexbox.scss';
@import 'primeflex/src/_spacing.scss';
@import 'primeflex/src/_elevation.scss';


html{

    margin: 0;
    height: 0;
    width: 100vw;
    height: 100vh;
    
    body{
        margin: 0;
        height: 0;
        width: 100%;
        height: 100%;
        font-family: 'Open Sans', sans-serif;

        #root{
            width: 100%;
            height: 100%;
        }
    }
}