@import '../../../../default';

.card-dashboard-header{

    width: 280px;
    min-width: 280px;
    height: 100px;
    border-radius: 5px;
    background-color: $color-tokin-green-3;
    box-sizing: border-box;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 30px;

    @media screen and (max-width: 500px) {
        max-width: 100%;
        width: 100%;
    }

    .title{
        font-weight: bold;
        align-self: center;
        .p-divider{
            &::before{
                border-color: #999 !important;
            }
        }
    }

    .content-value{
        grid-column: 1 / span 2;
        justify-self: flex-end;
        box-sizing: border-box;
        padding: 0 10px;
        font-weight: 700;
        font-size: 1.2em;
    }

    
}