@import '../../default';

.page-login{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../../../image/bg_login.jpg);
    background-size: cover;
    background-position: center center;
    position: relative;

    &::before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: linear-gradient(45deg, $color-tokin-green-1, $color-tokin-green-2);
        opacity: 0.4;
        z-index: 1;
    }

    .box{
        position: relative;
        width: 96%;
        max-width: 400px;
        box-sizing: border-box;
        padding: 20px;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 3px;
        z-index: 5;

        .logo{
            width: 100%;
            max-width: 150px;
        }

        .p-inputtext, .p-inputmask, .p-password, .p-password input, .p-button{
            width: 100%;
        }

        label{
            margin: 10px 0;
            display: block;
            font-size: 0.8em;
            font-weight: 800;
        }
    }
}